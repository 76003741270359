import axios from "axios";
import { ADD_CONTACT } from "../constants/apis.constants.ts";

export const addContact = async (contact: {
  nom: string;
  prenom: string;
  email: string;
  numTel: string;
  description;
}) => {
  return axios.post(ADD_CONTACT, contact);
};

import { createSlice } from "@reduxjs/toolkit";
import { fetchPrayerTimes } from "./prayerTimeThunk.ts";

interface PrayerTimeState {
  loading: string;
  times: any;
  date: any;
  error: string;
}

const initialState: PrayerTimeState = {
  loading: "succeeded",
  error: "",
  times: {},
  date: null,
};

const prayerTimeSlice = createSlice({
  name: "prayerTimeSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPrayerTimes.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(fetchPrayerTimes.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.times = action.payload.timings;
        state.date = action.payload.date;
      })
      .addCase(fetchPrayerTimes.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message || "";
      });
  },
});

export default prayerTimeSlice.reducer;

import axios from "axios";
import { GET_ADHKAR_BY_NAME } from "../constants/apis.constants.ts";

export const getDhikrByName = async (titleDhikr) => {
  const response = await axios.get(`${GET_ADHKAR_BY_NAME}?name=${titleDhikr}`);
  if (response?.data) {
    return response.data.data;
  }
  return [];
};

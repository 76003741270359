import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./DetailDhikrPage.scss";
import { faArrowLeft, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getDhikrByName } from "../../services/adhkar.service.ts";
import { useEffect, useState } from "react";
import AudioPlayer from "../../components/common/audioPlayer/AudioPlayer.jsx";
import Loader from "../../components/common/Loader/Loader.jsx";

const DetailDhikrPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { titleDhikr } = useParams();
  const [adhkar, setAdhkar] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getDhikrByName(titleDhikr)
      .then((result) => {
        setAdhkar(result);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [titleDhikr]);

  return loading ? (
    <Loader />
  ) : (
    <div className="pages-position">
      <div className="pages-header">
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="icon cursor-pointer"
          onClick={() => {
            navigate(-1);
          }}
        />
        <span className="title-page">{t(`adhkar.TITLES.${titleDhikr}`)}</span>
      </div>
      <div className="details-dhikr-body">
        <div className="adhkars">
          {adhkar.map((dhikr, index) => (
            <Dhikr key={index} dataDhikr={dhikr}></Dhikr>
          ))}
        </div>
      </div>
    </div>
  );
};

const Dhikr = ({ dataDhikr }) => {
  const DisplayAudio = ({ audio }) => {
    return (
      <div className="flex flex-col items-center justify-center">
        <div className="bg-white rounded-lg pb-8 w-full max-w-md">
          <div className="flex flex-col items-center justify-center">
            <AudioPlayer audioUrl={audio}></AudioPlayer>
          </div>
        </div>
      </div>
    );
  };

  const Accordion = ({ title, content }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
      <div className="border rounded-md mb-2 shadow">
        <button
          className="w-full bg-primary text-primary-foreground font-medium px-4 py-3 flex items-center justify-between hover:bg-primary/90 focus:outline-none focus-visible:ring-2 focus-visible:ring-ring"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span style={{ color: "var(--black)" }}>{title}</span>
          <span
            className={`transition-transform ${isOpen ? "rotate-180" : ""}`}
          >
            <FontAwesomeIcon
              icon={faCaretDown}
              className="icon icon-accordion"
            />
          </span>
        </button>
        <div
          className={`overflow-hidden transition-[max-height] duration-300 ${
            isOpen ? "max-h-[1000px]" : "max-h-0"
          }`}
        >
          <div className="p-4">
            {title !== "Audio" ? (
              <p style={{ color: "var(--blue-black)" }}>{content}</p>
            ) : (
              <DisplayAudio audio={content}></DisplayAudio>
            )}
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="details-dhikr-container">
      <div className="content-dhikr">
        <div className="details-dhikr-content">
          <h2 style={{ whiteSpace: "pre-line" }}>{dataDhikr.arabicText}</h2>
        </div>
        <div className="number-repeat-dhikr">{dataDhikr.repetitions}</div>
      </div>
      <div className="details-dhikr">
        <Accordion
          title="Translation"
          content={dataDhikr.details.Translation}
        ></Accordion>
        <Accordion
          title="Transliteration"
          content={dataDhikr.details.Transliteration}
        ></Accordion>
        {dataDhikr.details.Audio && (
          <Accordion
            title="Audio"
            content={dataDhikr.details.Audio}
          ></Accordion>
        )}
      </div>
    </div>
  );
};

export default DetailDhikrPage;

import { useNavigate } from "react-router-dom";
import "./CardDhikr.scss";
import { useTranslation } from "react-i18next";

const CardDhikr = ({ image, title }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="card-dhikr" onClick={() => navigate(`/dhikr/${title}`)}>
      <img src={image} alt="" />
      <span>{t(`adhkar.TITLES.${title}`)}</span>
    </div>
  );
};

export default CardDhikr;

import { useTranslation } from "react-i18next";
import "./SurahPage.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchListSurah,
  fetchQuranBySorah,
} from "../../features/quran/quranThunk.ts";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks.ts";
import CardSorah from "../../components/specific/cardSorah/CardSorah.jsx";
import NumAyahIcon from "../../assets/images/num-aya-border-green.svg";
import RecitationQuran from "../../components/specific/recitationQuran/RecitationQuran.jsx";
import Loader from "../../components/common/Loader/Loader.jsx";
import bismillahImage from "../../assets/images/bismillah.svg";
import ListDropDown from "../../components/common/listDropDown/ListDropDown.jsx";
import { LIST_RECITERS } from "../../constants/static_data.constants.ts";

const SurahPage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { numSorah } = useParams(); // Get the 'numSorah' parameter from the URL
  const dispatch = useAppDispatch();
  const dataSorah = useAppSelector(
    (state) => state.quran && state.quran.dataSelectedSorah
  );
  const loading = useAppSelector((state) => state.quran && state.quran.loading);
  const listSorah = useAppSelector((state) => state.quran.listSorah);
  const reciter = useAppSelector((state) => state.quran.reciter);

  useEffect(() => {
    dispatch(fetchQuranBySorah({ numSorah, reciter: reciter.value }));
    if (!listSorah.length) {
      dispatch(fetchListSurah());
    }
  }, [numSorah]);

  return loading === "loading" ? (
    <Loader />
  ) : (
    <div className="surah-page pages-position">
      <div className="surah-page-header">
        <div className="recitation-block">
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="icon icon-arrow-left"
            onClick={() => {
              navigate("/quran");
            }}
          />
          <RecitationQuran from="surahPage"></RecitationQuran>
          <ListDropDown
            list={LIST_RECITERS}
            selectedItem={
              i18n.language == "ar" ? reciter.label_ar : reciter.label
            }
            type="reciter"
          ></ListDropDown>
        </div>
        {/* <span className="title-page">{t("quran.RECITE_LISTEN_QUORAN")}</span> */}
      </div>
      {/* Quran by sorah */}
      <div className="surah-body">
        <div className="quran-and-recitation">
          <div className="text-quran">
            <div className="name-of-allah">
              <span className="flex justify-center">
                {dataSorah && dataSorah.data.nameOfAllah && (
                  <img src={bismillahImage}></img>
                )}
              </span>
              {/* <span>
                {dataSorah &&
                  dataSorah.data.nameOfAllah &&
                  "بِسْمِ ٱللَّهِ ٱلرَّحْمَٰنِ ٱلرَّحِيمِ"}
              </span> */}
            </div>
            {dataSorah &&
              dataSorah.data.ayahs.map((ayah, index) => (
                <div key={index} className="ayah ">
                  <span> {ayah.text} </span>
                  <div className="icon-ayah">
                    <img src={NumAyahIcon} alt="" />
                    <span className="num-ayah">{ayah.numberInSurah}</span>
                  </div>
                </div>
              ))}
          </div>
        </div>
        {/* list Sorahs */}
        <div className="list-sorahs">
          {listSorah.map((sorah) => (
            <CardSorah
              data={sorah}
              key={sorah.number}
              selectedSorah={sorah.number == numSorah}
              from="surahPage"
            ></CardSorah>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SurahPage;

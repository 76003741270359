import "./PinMarker.scss";
import markerMosque from "../../../assets/icons/marker-mosque.svg";
import { Pin, AdvancedMarker, useMap } from "@vis.gl/react-google-maps";
import { useCallback } from "react";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks.ts";
import { selectMosque } from "../../../features/mosque/mosqueSlice.ts";
import { Circle } from "./Circle.tsx";

const PinMarker = ({ pois, centerCircle }) => {
  const dispatch = useAppDispatch();
  const listMosques = useAppSelector((state) => state.mosque.listMosques);
  const radius = useAppSelector((state) => state.mosque.radiusGettingMosque);

  const map = useMap();
  const handleClick = useCallback((_ev: google.maps.MapMouseEvent, index) => {
    if (!map) return;
    dispatch(selectMosque(index));
  }, []);

  return (
    <div>
      <Circle
        radius={radius}
        center={centerCircle}
        strokeColor={"#406259"}
        strokeOpacity={1}
        strokeWeight={1}
        fillColor={"#406259"}
        fillOpacity={0.2}
      />
      {pois.map((poi, index) => {
        const icon = document.createElement("div");
        icon.innerHTML = `<img src=${markerMosque} width="100px">`;

        return (
          <AdvancedMarker
            position={poi}
            title={listMosques[index].name}
            key={index}
            onClick={() => handleClick(poi, index)}
          >
            <Pin glyph={icon} />
          </AdvancedMarker>
        );
      })}
    </div>
  );
};

export default PinMarker;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ListDropDown.scss";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useAppDispatch } from "../../../app/hooks.ts";
import { selectReciter } from "../../../features/quran/quranSlice.ts";

const ListDropDown = ({ list, selectedItem, type }) => {
  const { i18n } = useTranslation();
  const [viewList, setViewList] = useState(false);

  return (
    <div>
      <div className="dropdown-list">
        <div className="name-selected" onClick={() => setViewList(!viewList)}>
          <span>{selectedItem}</span>

          <FontAwesomeIcon icon={faAngleDown} className="icon forward" />
        </div>
        {viewList && (
          <DropDownListSorahs
            language={i18n.language}
            list={list}
            setViewList={setViewList}
            type={type}
            selectedItem={selectedItem}
          ></DropDownListSorahs>
        )}
      </div>
    </div>
  );
};

const DropDownListSorahs = ({
  language,
  list,
  setViewList,
  type,
  selectedItem,
}) => {
  const dispatch = useAppDispatch();
  const selectItem = (itemSelected) => {
    setViewList(false);
    if (type === "reciter") {
      dispatch(selectReciter(itemSelected));
    }
  };
  return (
    <div className="list-items-container">
      {list.map((item, index) => {
        const label = language === "ar" ? item.label_ar : item.label;
        if (label !== selectedItem) {
          return (
            <div
              className="item-name"
              key={index}
              onClick={() => selectItem(item)}
            >
              <span>{label}</span>
            </div>
          );
        }
      })}
    </div>
  );
};

export default ListDropDown;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./PolitiqueConfidentialite.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Footer from "../../components/layout/Footer";

const PolitiqueConfidentialite = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="pages-position">
      <div className="pages-header">
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="icon"
          onClick={() => {
            navigate(-1);
          }}
        />
        <span className="title-page">
          {t("politique_confidentialite.TITLE")}
        </span>
      </div>
      <div className="politique-confidentialite-body">
        <div>
          <h2>1. Introduction</h2>
          <p>
            Bienvenue sur le site MUSLIM Community. Nous attachons une grande
            importance à la confidentialité de vos données personnelles. Cette
            politique de confidentialité vise à vous informer des informations
            que nous collectons, de la manière dont nous les utilisons, et de
            vos droits concernant ces informations.
          </p>
        </div>

        <div>
          <h2>2. Informations Collectées</h2>
          <h3>Informations que vous nous fournissez :</h3>
          <ul>
            <li>
              <span className="font-bold">Inscription :</span>
              <span>
                Lorsque vous vous inscrivez sur notre site, nous collectons des
                informations telles que votre nom, votre adresse e-mail, et
                d'autres informations nécessaires pour créer votre compte.
              </span>
            </li>
            <li>
              <span className="font-bold"> Formulaires de contact :</span>
              <span>
                Si vous nous contactez via nos formulaires, nous collectons les
                informations fournies, comme votre nom, votre adresse e-mail, et
                le contenu de votre message.
              </span>
            </li>
          </ul>
          <h3>Informations collectées automatiquement :</h3>
          <ul>
            <li>
              <span className="font-bold">Cookies :</span>
              <span>
                Nous utilisons des cookies pour améliorer votre expérience
                utilisateur. Les cookies sont de petits fichiers texte stockés
                sur votre appareil qui nous aident à suivre et à stocker vos
                préférences.
              </span>
            </li>
            <li>
              <span className="font-bold"> Logs du serveur :</span>
              <span>
                Nous collectons des informations telles que votre adresse IP, le
                type de navigateur, les pages visitées, et l'heure de votre
                visite pour analyser les tendances et améliorer notre service.
              </span>
            </li>
          </ul>
        </div>
        <div>
          <h2>3. Utilisation des Informations</h2>
          <p>Les informations que nous collectons sont utilisées pour :</p>
          <ul>
            <li>
              <span className="font-bold"> Gérer votre compte :</span>
              <span>
                Nous utilisons vos informations pour créer et gérer votre compte
                utilisateur.
              </span>
            </li>
            <li>
              <span className="font-bold">Améliorer nos services :</span>
              <span>
                Vos retours nous permettent de comprendre vos besoins et
                d'améliorer notre site et nos services.
              </span>
            </li>
            <li>
              <span className="font-bold"> Communiquer avec vous :</span>
              <span>
                Nous utilisons vos informations pour répondre à vos demandes et
                vous envoyer des mises à jour importantes concernant nos
                services.
              </span>
            </li>
          </ul>
        </div>
        <div>
          <h2>4. Partage des Informations</h2>
          <p>
            Nous ne vendons, n'échangeons, ni ne transférons vos informations
            personnelles à des tiers sans votre consentement, sauf dans les cas
            suivants :
          </p>
          <ul>
            <li>
              <span className="font-bold">Prestataires de services :</span>
              <span>
                Nous pouvons partager vos informations avec des tiers de
                confiance qui nous aident à gérer notre site et à fournir nos
                services, sous réserve qu'ils acceptent de garder ces
                informations confidentielles.
              </span>
            </li>
            <li>
              <span className="font-bold">Exigences légales :</span>
              <span>
                Nous pouvons divulguer vos informations si la loi l'exige ou
                pour protéger nos droits, notre propriété, ou notre sécurité.
              </span>
            </li>
          </ul>
        </div>

        <div>
          <h2> 5. Sécurité des Informations</h2>
          <p>
            Nous mettons en œuvre des mesures de sécurité pour protéger vos
            informations personnelles contre l'accès non autorisé, la
            modification, la divulgation ou la destruction. Cependant, aucune
            méthode de transmission sur Internet ou de stockage électronique
            n'est totalement sécurisée, nous ne pouvons donc garantir une
            sécurité absolue.
          </p>
        </div>

        <div>
          <h2>6. Vos Droits</h2>
          <p>Vous avez le droit de :</p>
          <ul>
            <li>
              <span className="font-bold">
                Accéder à vos informations personnelles :
              </span>
              <span>
                Vous pouvez demander une copie des informations personnelles que
                nous détenons sur vous.
              </span>
            </li>
            <li>
              <span className="font-bold">Corriger vos informations :</span>
              <span>
                Si vous constatez que vos informations personnelles sont
                incorrectes ou incomplètes, vous pouvez demander leur
                correction.
              </span>
            </li>
            <li>
              <span className="font-bold">Supprimer vos informations :</span>
              <span>
                Vous pouvez demander la suppression de vos informations
                personnelles, sous réserve de certaines exceptions légales.
              </span>
            </li>
          </ul>
          <p>
            Pour exercer ces droits, veuillez nous contacter à l'adresse
            suivante : [email de contact].
          </p>
        </div>

        <div>
          <h2>7. Modifications de cette Politique de Confidentialité</h2>
          <p>
            Nous nous réservons le droit de modifier cette politique de
            confidentialité à tout moment. Nous vous informerons de toute
            modification en publiant la nouvelle politique sur cette page. Nous
            vous encourageons à consulter cette politique régulièrement pour
            rester informé de la manière dont nous protégeons vos informations.
          </p>
        </div>

        <div>
          <h2>8. Contact </h2>
          <p>
            Pour toute question concernant cette politique de confidentialité,
            veuillez nous contacter à l'adresse suivante :
            contact@muslimcommunity.com
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PolitiqueConfidentialite;

// const BASE_URL = process.env.baseIP;
// const BASE_URL = "http://localhost:4400";
const BASE_URL = "https://backend-dev.mslmcommunity.com";
// const BASE_URL = "http://192.168.20.34:4400";

export const PRAYER_TIMES_API = "https://api.aladhan.com/v1/timings";
export const GET_QURAN_TEXT_AUDIO_API = "https://api.alquran.cloud/v1/surah/";

export const GET_QURAN_RECITATION_API =
  "https://cdn.islamic.network/quran/audio-surah/128/";
export const GET_LIST_SURAH_API = "https://api.alquran.cloud/v1/surah";
export const GET_LIST_MOSQUES = BASE_URL + "/api/getListMosques";
// export const GET_LIST_MOSQUES =
//   "https://maps.googleapis.com/maps/api/place/nearbysearch/json";

export const GET_ADHKAR_BY_NAME = BASE_URL + "/api/adhkar/adhkarbyname";
export const ADD_CONTACT = BASE_URL + "/api/auth/addContact";
export const GET_PARAM_BY_NAME = BASE_URL + "/api/parameter/parameterByName/";
export const GET_NAMES_OF_ALLAH_AUDIO =
  BASE_URL + "/public/namesOfAllah/lotfibouchnak.mp3";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchListSurah, fetchQuranBySorah } from "./quranThunk.ts";

interface QuranState {
  loading: string;
  dataSelectedSorah: any;
  listSorah: any[];
  reciter: { label: string; label_ar: string; value: string };
  selectedSurah: any;
  error: string;
}

const initialState: QuranState = {
  loading: "succeeded",
  dataSelectedSorah: null,
  listSorah: [],
  reciter: { label: "Alafasy", label_ar: "العفاسي", value: "ar.alafasy" },
  selectedSurah: { number: 1 },
  error: "",
};

const quranSlice = createSlice({
  name: "quranSlice",
  initialState,
  reducers: {
    changeSurahReducer(state, action: PayloadAction<string>) {
      if (action.payload === "previous") {
        if (state.selectedSurah?.number !== 1) {
          state.selectedSurah =
            state.listSorah[state.selectedSurah?.number - 2];
        } else {
          state.selectedSurah = state.listSorah[113];
        }
      } else if (action.payload === "next") {
        if (state.selectedSurah?.number === 114) {
          state.selectedSurah = state.listSorah[0];
        } else {
          state.selectedSurah = state.listSorah[state.selectedSurah?.number];
        }
      }
    },
    selectSurahReducer(state, action: PayloadAction<number>) {
      state.selectedSurah = state.listSorah[action.payload - 1];
    },
    selectReciter(state, action: PayloadAction<any>) {
      state.reciter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuranBySorah.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(fetchQuranBySorah.fulfilled, (state, action) => {
        state.loading = "succeeded";
        if (
          action.payload.data.ayahs[0].text.startsWith(
            "بِسْمِ ٱللَّهِ ٱلرَّحْمَٰنِ ٱلرَّحِيمِ"
          )
        ) {
          action.payload.data.ayahs[0].text =
            action.payload.data.ayahs[0].text.replace(
              "بِسْمِ ٱللَّهِ ٱلرَّحْمَٰنِ ٱلرَّحِيمِ",
              ""
            );
          action.payload.data.nameOfAllah = true;
        }

        state.dataSelectedSorah = action.payload;
      })
      .addCase(fetchQuranBySorah.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message || "";
      })

      .addCase(fetchListSurah.fulfilled, (state, action) => {
        state.listSorah = action.payload || [];
        state.selectedSurah = action.payload?.length ? action.payload[0] : null;
      })
      .addCase(fetchListSurah.rejected, (state, action) => {
        state.error = action.error.message || "";
      });
  },
});

export default quranSlice.reducer;
export const { changeSurahReducer, selectSurahReducer, selectReciter } =
  quranSlice.actions;

import "./Error404.scss";

const Error404 = () => {
  return (
    <div className="error-not-found">
      <h1>Error 404</h1>
    </div>
  );
};
export default Error404;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { getListMosques } from "../../services/googleMaps.service.ts";

export const fetchListMosques = createAsyncThunk(
  "fetchListMosques",
  async (params: {
    position: { lat: number; lng: number };
    radius: number;
  }) => {
    const data = await getListMosques(params.position, params.radius);
    return data;
  }
);

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ZakatPage.scss";
import { faAnglesRight, faAnglesLeft } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import { GET_PARAM_BY_NAME } from "../../constants/apis.constants.ts";

const ZakatPage = () => {
  const { t } = useTranslation();
  const [numberSteps, setNumberSteps] = useState(0);
  const [step, setStep] = useState(0);
  const [screenWidth, setScreenWidth] = useState(0);
  const [listPossessionsChecked, setListPossessionsChecked] = useState([]);
  const [listDepensesChecked, setListDepensesChecked] = useState([]);

  useEffect(() => {
    setScreenWidth(window.innerWidth);
    window.addEventListener("resize", () => setScreenWidth(window.innerWidth));
  }, []);

  const handleNextStep = () => {
    if (step === 0) {
      if (!listPossessionsChecked.length) {
        toast.error(t("alert.POSSESSION_REQUIRED"));
      } else if (!listDepensesChecked.length) {
        toast.error(t("alert.EXPENSE_REQUIRED"));
      } else {
        setStep((currentStep) => currentStep + 1);
      }
    } else if (listPossessionsChecked.length >= step) {
      // steps possessions
      let filledAmout = false;
      for (let j = 0; j < listPossessionsChecked[step - 1].list.length; j++) {
        if (listPossessionsChecked[step - 1].list[j].amount !== 0) {
          filledAmout = true;
          break;
        }
      }
      if (!filledAmout) {
        return toast.error(t("alert.ONE_REQUIRED"));
      }
      setStep((currentStep) => currentStep + 1);
    } else {
      // step depenses
      if (listDepensesChecked.find((el) => !el.amount)) {
        return toast.error(t("alert.EXPENSES_REQUIRED"));
      } else {
        setStep((currentStep) => currentStep + 1);
      }
    }
  };

  return (
    <div className="pages-position">
      <div className="pages-header">
        <span className="title-page">{t("zakat.TITLE")}</span>
      </div>
      <div className="zakat-calculator-body">
        <div
          className="w-full p-4 min-700-screen:p-8"
          style={{ minHeight: "50vh" }}
        >
          {step === 0 && (
            <StepZero
              setNumberSteps={setNumberSteps}
              listDepensesChecked={listDepensesChecked}
              setListDepensesChecked={setListDepensesChecked}
              setListPossessionsChecked={setListPossessionsChecked}
              listPossessionsChecked={listPossessionsChecked}
            ></StepZero>
          )}
          {step === 1 && (
            <StepPossession
              possession={listPossessionsChecked[0]}
              screenWidth={screenWidth}
              listPossessionsChecked={listPossessionsChecked}
              listDepensesChecked={listDepensesChecked}
              setListPossessionsChecked={setListPossessionsChecked}
            ></StepPossession>
          )}
          {step === 2 &&
            (listPossessionsChecked.length > 1 ? (
              <StepPossession
                possession={listPossessionsChecked[1]}
                screenWidth={screenWidth}
                listPossessionsChecked={listPossessionsChecked}
                listDepensesChecked={listDepensesChecked}
                setListPossessionsChecked={setListPossessionsChecked}
              ></StepPossession>
            ) : (
              <StepSpending
                listDepenses={listDepensesChecked}
                screenWidth={screenWidth}
                listPossessionsChecked={listPossessionsChecked}
                listDepensesChecked={listDepensesChecked}
                setListDepensesChecked={setListDepensesChecked}
              />
            ))}
          {step === 3 &&
            (listPossessionsChecked.length > 2 ? (
              <StepPossession
                possession={listPossessionsChecked[2]}
                screenWidth={screenWidth}
                listPossessionsChecked={listPossessionsChecked}
                listDepensesChecked={listDepensesChecked}
                setListPossessionsChecked={setListPossessionsChecked}
              ></StepPossession>
            ) : (
              step !== numberSteps + 1 && (
                <StepSpending
                  listDepenses={listDepensesChecked}
                  screenWidth={screenWidth}
                  listPossessionsChecked={listPossessionsChecked}
                  listDepensesChecked={listDepensesChecked}
                  setListDepensesChecked={setListDepensesChecked}
                />
              )
            ))}
          {step === 4 && step !== numberSteps + 1 && (
            <StepSpending
              listDepenses={listDepensesChecked}
              screenWidth={screenWidth}
              listPossessionsChecked={listPossessionsChecked}
              listDepensesChecked={listDepensesChecked}
              setListDepensesChecked={setListDepensesChecked}
            />
          )}
          {step === numberSteps + 1 && (
            <Result
              listPossessionsChecked={listPossessionsChecked}
              listDepensesChecked={listDepensesChecked}
            ></Result>
          )}
        </div>

        <div className="w-full flex justify-between">
          {step !== 0 ? (
            <div
              className="cursor-pointer flex items-center"
              onClick={() => setStep((currentStep) => currentStep - 1)}
            >
              <FontAwesomeIcon icon={faAnglesLeft} className="icon" />
              <span className="text-base xl:text-xl md:text-lg text-green font-bold">
                {t("zakat.PREVIOUS")}
              </span>
            </div>
          ) : (
            <div></div>
          )}
          {step <= numberSteps ? (
            <div
              className="cursor-pointer flex items-center"
              onClick={handleNextStep}
            >
              <span className="text-base xl:text-xl md:text-lg text-green font-bold">
                {step === 0
                  ? t("zakat.START")
                  : step === numberSteps
                  ? t("zakat.DONE")
                  : t("zakat.NEXT")}
              </span>
              <FontAwesomeIcon icon={faAnglesRight} className="icon" />
            </div>
          ) : (
            <div></div>
          )}
        </div>

        {screenWidth >= 700 && step !== numberSteps + 1 && (
          <div className="card-calculator">
            <CardCalculator
              listPossessionsChecked={listPossessionsChecked}
              listDepensesChecked={listDepensesChecked}
            />
          </div>
        )}

        <div className="flex flex-col items-center">
          <span className="mb-2 font-bold">
            Step {step > numberSteps ? numberSteps : step} of {numberSteps}
          </span>

          <input
            type="range"
            id="zakat-bar"
            value={
              numberSteps
                ? step > numberSteps
                  ? 100
                  : (step / numberSteps) * 100
                : 0
            }
            className="rounded-lg overflow-hidden appearance-none h-3 w-128"
          />
        </div>
      </div>
    </div>
  );
};

const StepZero = ({
  setNumberSteps,
  listDepensesChecked,
  setListDepensesChecked,
  setListPossessionsChecked,
  listPossessionsChecked,
}) => {
  const listPossession = [
    {
      value: "or",
      name: "Or et Argent",
      description:
        "If you're not sure how much your gold and silver is worth you can enter the weight in grams",
      list: [
        { value: "or", name: "Or", amount: 0 },
        { value: "argent", name: "Argent", amount: 0 },
      ],
    },
    {
      value: "cache",
      name: "Cache",
      description:
        "Saisissez le montant d'espèces dont vous disposez, y compris l'argent sur votre(vos) compte(s) bancaire(s), quelle qu'en soit la source (par exemple, salaire, revenus locatifs, avantages sociaux, etc.).",
      list: [
        { value: "encaisse", name: "Encaisse", amount: 0 },
        {
          value: "especes_bancaire",
          name: "Espèces sur des comptes bancaires",
          amount: 0,
        },
      ],
    },
    {
      value: "actifs",
      name: "Actifs commerciaux",
      description:
        "Les actifs de l'entreprise comprennent la trésorerie, les créances (l'argent dû à l'entreprise) et les actions. Les actions doivent être valorisées au prix de détail. Si vous avez acheté une propriété dans le seul but de la revendre, incluez une estimation de la valeur actuelle de votre valeur nette dans la propriété.",
      list: [
        { value: "encaisse", name: "Cash", amount: 0 },
        { value: "creances", name: "Créances", amount: 0 },
        { value: "action", name: "Action", amount: 0 },
      ],
    },
  ];
  const listDepenses = [
    { value: "personnal_depense", name: "Dépenses personnelles", amount: 0 },
    {
      value: "professional_depense",
      name: "Dépenses professionnelles",
      amount: 0,
    },
    { value: "money_depense", name: "L'argent que vous devez", amount: 0 },
  ];

  const handleCheckPossession = (event, possession) => {
    const checked = event.target.checked;
    if (checked) {
      setNumberSteps((currentNumberStep) => currentNumberStep + 1);
      setListPossessionsChecked((currentList) =>
        currentList.concat([possession])
      );
    } else {
      setNumberSteps((currentNumberStep) => currentNumberStep - 1);
      setListPossessionsChecked((currentList) =>
        currentList.filter((el) => el.value !== possession.value)
      );
    }
  };

  const handleCheckSpending = (event, spending) => {
    if (event.target.checked) {
      if (!listDepensesChecked.length) {
        setNumberSteps((currentNumberStep) => currentNumberStep + 1);
      }
      setListDepensesChecked((currentList) => currentList.concat([spending]));
    } else {
      if (listDepensesChecked.length === 1) {
        setNumberSteps((currentNumberStep) => currentNumberStep - 1);
      }
      setListDepensesChecked((currentList) =>
        currentList.filter((el) => el.value !== spending.value)
      );
    }
  };

  const compareCheck = (list, el) => {
    return list.find((x) => x.value === el.value);
  };

  return (
    <div className="">
      <div className="mb-8">
        <h1 className="mb-2">Je possède</h1>
        <div className="px-2 min-700-screen:px-8">
          <ul className="font-medium rounded-lg">
            {listPossession.map((el, index) => (
              <li className="w-full" key={index}>
                <div className="flex items-center ps-3">
                  <input
                    id={el.value}
                    type="checkbox"
                    value={el.value}
                    className="w-4 h-4 text-green bg-green border-green rounded focus:ring-green focus:ring-2 cursor-pointer accent-green"
                    onChange={(event) => handleCheckPossession(event, el)}
                    checked={compareCheck(listPossessionsChecked, el)}
                  />
                  <label
                    htmlFor={el.value}
                    className="w-full py-3 ms-2 font-medium cursor-pointer"
                  >
                    {el.name}
                  </label>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div>
        <h1 className="mb-2">Mes Dépenses</h1>
        <div className="px-2 min-700-screen:px-8">
          <ul className="font-medium rounded-lg">
            {listDepenses.map((el, index) => (
              <li className="w-full" key={index}>
                <div className="flex items-center ps-3">
                  <input
                    id={el.value}
                    type="checkbox"
                    value={el.value}
                    className="w-4 h-4 text-green bg-green border-gray-300 rounded focus:ring-green focus:green cursor-pointer accent-green"
                    onChange={(event) => handleCheckSpending(event, el)}
                    checked={compareCheck(listDepensesChecked, el)}
                  />
                  <label
                    htmlFor={el.value}
                    className="w-full py-3 ms-2 font-medium cursor-pointer"
                  >
                    {el.name}
                  </label>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

const StepPossession = ({
  possession,
  screenWidth,
  listPossessionsChecked,
  listDepensesChecked,
  setListPossessionsChecked,
}) => {
  const changeValuePossession = (index, event) => {
    setListPossessionsChecked((Possessions) => {
      const possessionsUpdated = [...Possessions];

      const posessionChecked = possessionsUpdated.find(
        (el) => el.name === possession.name
      );
      if (posessionChecked.list[index]) {
        posessionChecked.list[index].amount = Number(event.target.value);
      }
      return possessionsUpdated;
    });
  };

  return (
    <div className="">
      <div className="mb-8">
        {screenWidth < 700 && (
          <div className="card-calculator small-screen">
            <CardCalculator
              listPossessionsChecked={listPossessionsChecked}
              listDepensesChecked={listDepensesChecked}
            />
          </div>
        )}
        <h1 className="mb-2">{possession.name}</h1>
        <div className="px-2 min-700-screen:px-8 min-700-screen:w-1/2">
          <p>{possession.description}</p>
          {possession.list.map((el, index) => (
            <div className="mb-2 min-700-screen:mb-4" key={index}>
              <h2>{el.name}</h2>
              <div className="flex w-full">
                <span className="inline-flex items-center px-3 text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 border-e-0 rounded-s-md">
                  TND
                </span>
                <input
                  type="number"
                  id={el.value}
                  className="rounded-none rounded-e-lg bg-gray-50 border text-gray-900 focus:ring-green focus:border-green block flex-1 text-sm border-gray-300 p-2 min-700-screen:p-2.5"
                  placeholder=""
                  value={el.amount}
                  onChange={(e) => changeValuePossession(index, e)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const StepSpending = ({
  listDepenses,
  screenWidth,
  listPossessionsChecked,
  listDepensesChecked,
  setListDepensesChecked,
}) => {
  const changeValueDepenses = (index, value) => {
    setListDepensesChecked((depenses) => {
      const depensesUpdated = [...depenses];
      depensesUpdated[index].amount = value;
      return depensesUpdated;
    });
  };

  return (
    <div className="min-700-screen:w-1/2">
      <div className="mb-8">
        {screenWidth < 700 && (
          <div className="card-calculator small-screen">
            <CardCalculator
              listPossessionsChecked={listPossessionsChecked}
              listDepensesChecked={listDepensesChecked}
            />
          </div>
        )}
        <h1 className="mb-2">Dépenses</h1>
        <div className="px-2 min-700-screen:px-8">
          <p>depenseeeeeeexxxses</p>
          {listDepenses.map((el, index) => (
            <div className="mb-2 min-700-screen:mb-4" key={index}>
              <h2>{el.name}</h2>
              <div className="flex w-full">
                <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 border-e-0 rounded-s-md">
                  TND
                </span>
                <input
                  type="number"
                  id={el.value}
                  className="rounded-none rounded-e-lg bg-gray-50 border text-gray-900 focus:ring-green focus:border-green block flex-1 text-sm border-gray-300 p-2.5"
                  placeholder=""
                  value={el.amount}
                  onChange={(e) =>
                    changeValueDepenses(index, Number(e.target.value))
                  }
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const Result = ({ listPossessionsChecked, listDepensesChecked }) => {
  const { t } = useTranslation();
  return (
    <div className="">
      <div className="mb-8">
        <h1 className="mb-2">{t("zakat.RESULT")}</h1>
        <div className="">
          <p>
            Voici un résumé des informations que vous avez fournies et du
            montant de la Zakat que vous devez verser.
          </p>
          <div className="flex result-container">
            <CardCalculator
              result={true}
              listPossessionsChecked={listPossessionsChecked}
              listDepensesChecked={listDepensesChecked}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const CardCalculator = ({
  result,
  listPossessionsChecked,
  listDepensesChecked,
}) => {
  const [nisab, setNisab] = useState(0);
  const { t } = useTranslation();

  const [resultCalculator, setResultCalculator] = useState({
    possessions: 0,
    depenses: 0,
    total: 0,
  });

  useEffect(() => {
    // Fetch the nisab value from the API
    axios
      .get(`${GET_PARAM_BY_NAME}nisab`)
      .then((response) => {
        setNisab(response.data.data[0].value);
      })
      .catch((_error) => {
        toast.error(t("alert.ECHEC_SERVER"));
      });
  }, []);

  useEffect(() => {
    setResultCalculator((currentCalculation) => {
      currentCalculation.possessions = 0;
      listPossessionsChecked?.forEach((item) => {
        item.list?.forEach((el) => {
          currentCalculation.possessions += el.amount;
        });
      });

      const newCalculation = { ...currentCalculation };
      return newCalculation;
    });
  }, [listPossessionsChecked]);

  useEffect(() => {
    setResultCalculator((currentCalculation) => {
      currentCalculation.depenses = 0;

      listDepensesChecked?.forEach((item) => {
        currentCalculation.depenses += item.amount;
      });

      const newCalculation = { ...currentCalculation };
      return newCalculation;
    });
  }, [listDepensesChecked]);

  return (
    <div className={result && "result-section"}>
      <div className="flex items-center justify-between">
        <h3>What I have</h3>
        <span>{resultCalculator?.possessions}</span>
      </div>
      <div className="flex items-center justify-between">
        <h3>what I owe</h3>
        <span>{resultCalculator?.depenses}</span>
      </div>
      <div className="flex items-center justify-between">
        <h3>is equal to:</h3>
        <span>
          {resultCalculator?.possessions - resultCalculator?.depenses}
        </span>
      </div>
      <div className="flex items-center justify-between">
        <h3>Today's Nisab is:</h3>
        <span>{nisab}</span>
      </div>
      {result && (
        <div className="flex items-center justify-between result">
          <h1>Zakat (2.5%) is:</h1>
          <span>
            {resultCalculator?.possessions - resultCalculator?.depenses > nisab
              ? (
                  (resultCalculator.possessions - resultCalculator.depenses) *
                  0.025
                ).toFixed(3)
              : 0}
          </span>
        </div>
      )}
    </div>
  );
};

export default ZakatPage;

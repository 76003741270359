import { useState } from "react";
import "./AudioPlayer.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlay, faCirclePause } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { formatTime } from "../../../utils/helpers";
import { handlePositionBarTime } from "../../../services/quran.service.ts";

const AudioPlayer = ({ audioUrl, colorInput, withBorder }) => {
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState("0:00");
  const [currentTime, setCurrentTime] = useState("0:00");
  const [currentPositionBarTime, setCurrentPositionBarTime] = useState(0);
  const [countTimeInterval, setCountTimeInterval] = useState(null);

  const handleAudio = () => {
    setPlaying(!playing);
    const audioElement = document.getElementById(audioUrl);
    if (audioElement) {
      setDuration(formatTime(audioElement.duration));
      if (!playing) {
        if (audioElement.currentTime >= audioElement.duration) {
          setCurrentTime("0:00");
        }
        audioElement.play();

        const interval = setInterval(() => {
          setCurrentTime((time) =>
            moment(time, "m:ss").add(1, "seconds").format("m:ss")
          );
          setCurrentPositionBarTime(
            () => (audioElement.currentTime / audioElement.duration) * 100
          );
          // if the audio is finished
          if (audioElement.currentTime >= audioElement.duration) {
            clearInterval(interval);
            setPlaying(false);
            setCurrentTime("0:00");
            setCurrentPositionBarTime(0);
          }
        }, 1000);
        setCountTimeInterval(interval);
      } else {
        clearInterval(countTimeInterval);
        audioElement.pause();
      }
    }
  };

  return (
    <div className={"audio-player " + (withBorder && "withBorder")}>
      <audio src={audioUrl} id={audioUrl}></audio>
      <button className="play" onClick={handleAudio}>
        <FontAwesomeIcon
          icon={!playing ? faCirclePlay : faCirclePause}
          className={"icon " + (!playing ? "faCirclePlay" : "faCirclePause")}
        />
      </button>
      <input
        className={
          "rounded-lg overflow-hidden appearance-none w-128 " +
          (withBorder ? "h-2" : "h-3")
        }
        style={{ backgroundColor: colorInput || "var(--back-gray-2)" }}
        type="range"
        id="seek-bar"
        value={currentPositionBarTime}
        onChange={(event) =>
          handlePositionBarTime(
            audioUrl,
            event.target.value,
            duration,
            setCurrentTime,
            setCurrentPositionBarTime
          )
        }
      />
      <span>{currentTime}</span> /<span>{duration}</span>
    </div>
  );
};

export default AudioPlayer;

import "./Loader.scss";
import Lottie from "lottie-react";
import loading from "../../../assets/lottie/loading.json";

const Loader = () => {
  return (
    <div className="flex items-center justify-center loader-container">
      <div style={{ width: "17em" }}>
        <Lottie animationData={loading} loop={true} />
      </div>
    </div>
  );
};

export default Loader;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlay,
  faForwardStep,
  faBackwardStep,
  faAngleDown,
  faCirclePause,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import "./RecitationQuran.scss";
import { GET_QURAN_RECITATION_API } from "../../../constants/apis.constants.ts";
import { useAppSelector, useAppDispatch } from "../../../app/hooks.ts";
import {
  changeSurahReducer,
  selectSurahReducer,
} from "../../../features/quran/quranSlice.ts";
import { useEffect, useState } from "react";
import { formatTime } from "../../../utils/helpers.js";
import moment from "moment";
import { handlePositionBarTime } from "../../../services/quran.service.ts";
import { useNavigate } from "react-router-dom";

const RecitationQuran = ({ from }) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState("0:00");
  const [currentTime, setCurrentTime] = useState("0:00");
  const [currentPositionBarTime, setCurrentPositionBarTime] = useState(0);
  const [countTimeInterval, setCountTimeInterval] = useState(null);
  const [viewListSorahs, setViewListSorahs] = useState(false);
  const { selectedSurah } = useAppSelector((state) => state.quran);
  const reciter = useAppSelector((state) => state.quran.reciter);
  const audioUrl = `${GET_QURAN_RECITATION_API}${reciter.value}/${selectedSurah.number}.mp3`;

  useEffect(() => {
    stopAudio();
  }, [reciter]);

  const changeSurah = (option) => {
    stopAudio();
    if (from === "surahPage") {
      let numSorah = 0;
      if (option === "next") {
        if (selectedSurah.number === 114) {
          numSorah = 1;
        } else {
          numSorah = selectedSurah.number + 1;
        }
      } else {
        if (selectedSurah.number === 1) {
          numSorah = 114;
        } else {
          numSorah = selectedSurah.number - 1;
        }
      }
      navigate("/quran/" + numSorah);
    } else {
      dispatch(changeSurahReducer(option));
    }
  };

  const pauseAudio = () => {
    const audioElement = document.getElementById(
      `${GET_QURAN_RECITATION_API}${reciter.value}/${selectedSurah.number}.mp3`
    );
    clearInterval(countTimeInterval);
    audioElement.pause();
    setPlaying(false);
  };

  const stopAudio = () => {
    pauseAudio();
    setDuration("0:00");
    setCurrentTime("0:00");
    setCurrentPositionBarTime(0);
  };

  const handleAudio = () => {
    setPlaying(!playing);
    const audioElement = document.getElementById(
      `${GET_QURAN_RECITATION_API}${reciter.value}/${selectedSurah.number}.mp3`
    );
    if (audioElement) {
      const format = audioElement.duration / 3600 >= 1 ? "H:mm:ss" : "m:ss";
      if (
        currentTime === "0:00:00" ||
        currentTime === "0:00" ||
        !audioElement.currentTime
      ) {
        setCurrentTime(format === "H:mm:ss" ? "0:00:00" : "0:00");
      }
      setDuration(formatTime(audioElement.duration));
      if (!playing) {
        if (audioElement.currentTime >= audioElement.duration) {
          setCurrentTime(format === "H:mm:ss" ? "0:00:00" : "0:00");
        }
        audioElement.play();

        const interval = setInterval(() => {
          setCurrentTime((time) =>
            moment(time, format).add(1, "seconds").format(format)
          );
          setCurrentPositionBarTime(
            () => (audioElement.currentTime / audioElement.duration) * 100
          );
          // if the audio is finished
          if (audioElement.currentTime >= audioElement.duration) {
            clearInterval(interval);
            setPlaying(false);
            setCurrentTime(format === "H:mm:ss" ? "0:00:00" : "0:00");
            setCurrentPositionBarTime(0);
          }
        }, 1000);
        setCountTimeInterval(interval);
      } else {
        clearInterval(countTimeInterval);
        audioElement.pause();
      }
    } else {
      setPlaying(false);
    }
  };

  return (
    <div
      className={
        "recitation-section " +
        (from === "surahPage" ? "recitation-with-text" : "recitation")
      }
    >
      <div
        className={
          "flex flex_col items-center justify-center " +
          (from === "surahPage" ? "gap-2" : "homePage")
        }
        style={{ width: "30%", height: "3.2em" }}
      >
        <FontAwesomeIcon
          icon={faBackwardStep}
          className="icon forward"
          onClick={() => {
            changeSurah("previous");
          }}
        />
        <FontAwesomeIcon
          icon={!playing ? faCirclePlay : faCirclePause}
          className="icon"
          onClick={handleAudio}
        />
        <FontAwesomeIcon
          icon={faForwardStep}
          className="icon forward"
          onClick={() => {
            changeSurah("next");
          }}
        />
      </div>
      {from !== "surahPage" && (
        <div
          style={{ width: "70%", height: "3.5em" }}
          className="flex flex_col items-center justify-center"
        >
          <div className="dropdown-quran">
            <div
              className="coran-name"
              onClick={() => setViewListSorahs(!viewListSorahs)}
            >
              <span>
                {selectedSurah &&
                  (i18n.language === "ar"
                    ? selectedSurah.name
                    : selectedSurah.englishName)}
              </span>

              <FontAwesomeIcon icon={faAngleDown} className="icon forward" />
            </div>
            {viewListSorahs && (
              <DropDownListSorahs
                language={i18n.language}
                handleViewListSorahs={setViewListSorahs}
                stopAudio={stopAudio}
              ></DropDownListSorahs>
            )}
          </div>
        </div>
      )}
      <div
        className={
          "flex w-full items-center " + (from === "surahPage" ? "" : "mt-4")
        }
      >
        <span className="ml-4">{currentTime}</span>
        <audio src={audioUrl} id={audioUrl}></audio>
        <input
          type="range"
          className="rounded-lg overflow-hidden appearance-none h-3 w-128"
          style={{
            backgroundColor:
              from === "surahPage"
                ? "var(--back-green-2)"
                : "var(--back-gray-2)",
          }}
          id="audio-bar"
          value={currentPositionBarTime}
          onChange={(event) =>
            handlePositionBarTime(
              audioUrl,
              event.target.value,
              duration,
              setCurrentTime,
              setCurrentPositionBarTime
            )
          }
        />
        <span className="mr-4">{duration}</span>
      </div>
    </div>
  );
};

const DropDownListSorahs = ({ language, handleViewListSorahs, stopAudio }) => {
  const dispatch = useAppDispatch();
  const listSorah = useAppSelector((state) => state.quran.listSorah);
  const selectSorah = (numberSorah) => {
    stopAudio();
    dispatch(selectSurahReducer(numberSorah));
    handleViewListSorahs(false);
  };
  return (
    <div className="list-sorahs-container">
      {listSorah.map((sorah, index) => (
        <div
          className="sorah-name"
          key={index}
          onClick={() => selectSorah(sorah.number)}
        >
          <span>{language === "ar" ? sorah.name : sorah.englishName}</span>
        </div>
      ))}
    </div>
  );
};

export default RecitationQuran;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./LearnPage.scss";
import { useNavigate, useParams } from "react-router-dom";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import {
  SALAT_STEPS,
  WUDHU_STEPS,
} from "../../constants/static_data.constants.ts";
import { useState } from "react";

const LearnPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { typeLearn } = useParams(); // Get the 'typeLearn' parameter from the URL
  const steps = typeLearn === "wudhu" ? WUDHU_STEPS : SALAT_STEPS;

  return (
    <div className="pages-position">
      <div className="pages-header">
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="icon"
          onClick={() => {
            navigate(-1);
          }}
        />
        <span className="title-page">
          {t(`learn.${typeLearn.toUpperCase()}.SUB_TITLE`)}
        </span>
      </div>

      <div className="flex gap-6 flex-wrap learn-body">
        {steps.map((step, index) => (
          <CardLearn
            image={step.image}
            name={step.name}
            key={index}
          ></CardLearn>
        ))}
      </div>
    </div>
  );
};

const CardLearn = ({ name, image }) => {
  const [displayDescription, setDisplayDescription] = useState(false);
  const toggleDescription = () => {
    setDisplayDescription(!displayDescription);
  };
  return (
    <div
      className="card-learn"
      onMouseEnter={(e) => {
        toggleDescription();
      }}
      onMouseLeave={(e) => {
        toggleDescription();
      }}
    >
      <img src={image} alt="" />
      {displayDescription && (
        <div className="description-card-learn">
          <h2>Wash your hands. </h2>
          <p>
            Use your left hand to wash your right hand. Do this three times.
            After that, use your right hand to wash your left hand three times.
            Make sure to wash in between your fingers and all the way up to your
            wrists.
          </p>
        </div>
      )}
    </div>
  );
};

export default LearnPage;

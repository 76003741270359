import { useTranslation } from "react-i18next";
import "./ListAdhkarPage.scss";
import CardDhikr from "../../components/specific/cardDhikr/CardDhikr";
import morningImage from "../../assets/images/dhikr/Morning Adhkar.png";
import evningImage from "../../assets/images/dhikr/Evening Adhkar.png";
import nightImage from "../../assets/images/dhikr/night.jpg";
import salatImage from "../../assets/images/dhikr/Salah.png";
import afterSalatImage from "../../assets/images/dhikr/After Salah.png";
import ruquiyahImage from "../../assets/images/dhikr/Ruqyah & Illness.png";
import praisesAllahImage from "../../assets/images/dhikr/Praises of Allah.png";
import salawatImage from "../../assets/images/dhikr/Salawat.png";
import quranDuasImage from "../../assets/images/dhikr/Duas.png";
import sunnahDuasImage from "../../assets/images/dhikr/Sunnah Duas.png";
import dhikrImage from "../../assets/images/dhikr/Dhikr_For_All_Times.png";
import istighfarImage from "../../assets/images/dhikr/Istighfar.png";

import wakingUpImage from "../../assets/images/dhikr/Waking up.png";
import nightmareImage from "../../assets/images/dhikr/Nightmare.jpg";
import clothesImage from "../../assets/images/dhikr/clothes.png";
import lavatoryImage from "../../assets/images/dhikr/lavatory__wudu.png";
import homeImage from "../../assets/images/dhikr/home.jpg";
import adhanImage from "../../assets/images/dhikr/Adhan & Masjid.jpg";
import istikharahImage from "../../assets/images/dhikr/Istikharah.png";
import gatheringsImage from "../../assets/images/dhikr/Gatherings.png";
import foodImage from "../../assets/images/dhikr/Food & Drink.jpg";
import travelImage from "../../assets/images/dhikr/travel.png";
import deathImage from "../../assets/images/dhikr/Death.png";
import protectionImage from "../../assets/images/dhikr/Protection of Iman.png";
import DifficultiesImage from "../../assets/images/dhikr/Difficulties_Happiness.png";
import hajjImage from "../../assets/images/dhikr/Hajj & Umrah.jpg";
import moneyImage from "../../assets/images/dhikr/money.jpg";
import mariageImage from "../../assets/images/dhikr/Marriage & Children.png";
import { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import Footer from "../../components/layout/Footer";

const ListAdhkarPage = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  const MAIN_DHIKR = [
    { title: "Morning_Adhkar", image: morningImage },
    { title: "Evening_Adhkar", image: evningImage },
    { title: "Before_Sleep", image: nightImage },
    { title: "Salah", image: salatImage },
    { title: "After_Salah", image: afterSalatImage },
    { title: "Ruqyah___Illness", image: ruquiyahImage },
    { title: "Praises_of_Allah", image: praisesAllahImage },
    { title: "Salawat", image: salawatImage },
    { title: "Qur_anic_Duas", image: quranDuasImage },
    { title: "Sunnah_Duas", image: sunnahDuasImage },
    { title: "Dhikr_For_All_Times", image: dhikrImage },
    { title: "Istighfar", image: istighfarImage },
  ];
  const OTHER_DHIKR = [
    { title: "Waking_up", image: wakingUpImage },
    { title: "Nightmares", image: nightmareImage },
    { title: "Clothes", image: clothesImage },
    { title: "Lavatory___Wudu", image: lavatoryImage },
    { title: "Home", image: homeImage },
    { title: "Adhan___Masjid", image: adhanImage },
    { title: "Istikharah", image: istikharahImage },
    { title: "Gatherings", image: gatheringsImage },
    { title: "Food___Drink", image: foodImage },
    { title: "Travel", image: travelImage },
    { title: "Death", image: deathImage },
    { title: "Protection_of_Iman", image: protectionImage },
    { title: "Difficulties___Happiness", image: DifficultiesImage },
    { title: "Hajj___Umrah", image: hajjImage },
    { title: "Money___Shopping", image: moneyImage },
    { title: "Marriage___Children", image: mariageImage },
  ];

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, []);

  return (
    <div className="pages-position">
      <div>
        <div className="header-duaa pages-header">
          <span className="title-page">{t("links.DHIKR")}</span>
        </div>
        {loading && <SkeletonLoaderAdhkar />}

        <div className="dhikr-body" style={{ display: loading ? "none" : "" }}>
          <h1>{t("adhkar.MAIN")}</h1>
          <div className="dhikr-container">
            {MAIN_DHIKR.map((dhikr, index) => (
              <CardDhikr
                image={dhikr.image}
                title={dhikr.title}
                key={index}
              ></CardDhikr>
            ))}
          </div>
          <h1>{t("adhkar.OTHER")}</h1>
          <div className="dhikr-container">
            {OTHER_DHIKR.map((dhikr, index) => (
              <CardDhikr
                image={dhikr.image}
                title={dhikr.title}
                key={index}
              ></CardDhikr>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const SkeletonLoaderAdhkar = () => {
  const [width, setWidth] = useState(20);
  const [height, setHeight] = useState(30);
  const [widthScreen, setWidthScreen] = useState(0);

  const handleResize = () => {
    setWidthScreen(window.innerWidth);
    if (window.innerWidth < 400) {
      setWidth(90);
    } else if (window.innerWidth < 700) {
      setWidth(40);
    } else if (window.innerWidth < 1000) {
      setWidth(28);
    } else {
      setWidth(20);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);

  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 400 160"
      backgroundColor="#f3f3f3"
      foregroundColor="#eaeeed"
    >
      <>
        <rect
          x="5%"
          y="10"
          rx="2"
          ry="2"
          width={width + "%"}
          height={height + "%"}
        />
        {widthScreen > 400 && (
          <rect
            x={5 + width + 2 + "%"}
            y="10"
            rx="2"
            ry="2"
            width={width + "%"}
            height={height + "%"}
          />
        )}
        {widthScreen > 700 && (
          <rect
            x={5 + width * 2 + 4 + "%"}
            y="10"
            rx="2"
            ry="2"
            width={width + "%"}
            height={height + "%"}
          />
        )}
        {widthScreen > 1000 && (
          <rect
            x={5 + width * 3 + 6 + "%"}
            y="10"
            rx="2"
            ry="2"
            width={width + "%"}
            height={height + "%"}
          />
        )}
      </>
      <>
        <rect
          x="5%"
          y={10 + height + "%"}
          rx="2"
          ry="2"
          width={width + "%"}
          height={height + "%"}
        />
        {widthScreen > 400 && (
          <rect
            x={5 + width + 2 + "%"}
            y={10 + height + "%"}
            rx="2"
            ry="2"
            width={width + "%"}
            height={height + "%"}
          />
        )}
        {widthScreen > 700 && (
          <rect
            x={5 + width * 2 + 4 + "%"}
            y={10 + height + "%"}
            rx="2"
            ry="2"
            width={width + "%"}
            height={height + "%"}
          />
        )}
        {widthScreen > 1000 && (
          <rect
            x="71%"
            y={10 + height + "%"}
            rx="2"
            ry="2"
            width={width + "%"}
            height={height + "%"}
          />
        )}
      </>
      <>
        <rect
          x="5%"
          y={10 + height * 2 + 5 + "%"}
          rx="2"
          ry="2"
          width={width + "%"}
          height={height + "%"}
        />
        {widthScreen > 400 && (
          <rect
            x={5 + width + 2 + "%"}
            y={10 + height * 2 + 5 + "%"}
            rx="2"
            ry="2"
            width={width + "%"}
            height={height + "%"}
          />
        )}
        {widthScreen > 700 && (
          <rect
            x={5 + width * 2 + 4 + "%"}
            y={10 + height * 2 + 5 + "%"}
            rx="2"
            ry="2"
            width={width + "%"}
            height={height + "%"}
          />
        )}
        {widthScreen > 1000 && (
          <rect
            x="71%"
            y={10 + height * 2 + 5 + "%"}
            rx="2"
            ry="2"
            width={width + "%"}
            height={height + "%"}
          />
        )}
      </>
    </ContentLoader>
  );
};

export default ListAdhkarPage;

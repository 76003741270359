import { createAsyncThunk } from "@reduxjs/toolkit";
import { getPrayerTimes } from "../../services/prayerTimes.service.ts";

export const fetchPrayerTimes = createAsyncThunk(
  "fetchPrayerTimes",
  async (data: { position: any; date: string }) => {
    const tinmings = await getPrayerTimes(data.position, data.date);
    return tinmings;
  }
);

import { useEffect, useState } from "react";
import "./QuranPage.scss";
import { useAppDispatch, useAppSelector } from "../../app/hooks.ts";
import { useTranslation } from "react-i18next";
import CardSorah from "../../components/specific/cardSorah/CardSorah.jsx";
import { fetchListSurah } from "../../features/quran/quranThunk.ts";
import { removeSpaces, removeDiacritics } from "../../utils/helpers.js";
import Footer from "../../components/layout/Footer.jsx";
import mushafVideo from "../../assets/videos/mushaf.mp4";

const QuranPage = () => {
  const listSorah = useAppSelector((state) => state.quran.listSorah);
  const [listSorahFiltered, setListSorahFiltered] = useState([]);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!listSorah.length) {
      dispatch(fetchListSurah());
    }
    document.querySelector("#video-quran").playbackRate = 0.5;
  }, []);

  useEffect(() => {
    setListSorahFiltered(listSorah);
  }, [listSorah]);

  const handleSearch = (event) => {
    if (event.target.value) {
      const serchedString = removeSpaces(
        removeDiacritics(event.target.value.toLowerCase())
      );
      const filteredQuran = listSorah.filter(
        (el) =>
          removeSpaces(el.englishName.toLowerCase()).includes(serchedString) ||
          removeSpaces(removeDiacritics(el.name)).includes(serchedString)
      );
      setListSorahFiltered(filteredQuran);
    } else {
      setListSorahFiltered(listSorah);
    }
  };

  return (
    <div className="quran-page pages-position">
      <div className="header">
        <video autoPlay muted loop id="video-quran">
          <source src={mushafVideo} type="video/mp4" />
          Your browser does not support HTML video.
        </video>
        <input
          type="search"
          placeholder={t("search")}
          onChange={handleSearch}
        />
      </div>
      <div className="quran-body">
        {listSorahFiltered.map((sorah) => (
          <CardSorah data={sorah} key={sorah.number}></CardSorah>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default QuranPage;

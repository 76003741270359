// Fonction pour vérifier si une chaîne est un email valide
export function isValidEmail(email) {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}

export function formatTime(timeSeconds, format) {
  const hours = Math.floor(timeSeconds / 3600);
  let minutes = Math.floor(timeSeconds / 60) - hours * 60;
  let seconds = Math.floor(timeSeconds % 60);
  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  if (hours || format === "H:mm:ss") {
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    return hours + ":" + minutes + ":" + seconds;
  } else return minutes + ":" + seconds;
}

/** to remove diacritics from arabic string */
export function removeDiacritics(str) {
  return str.normalize("NFD").replace(/[\u064B-\u0652]/g, "");
}

/** to remove spaces from string */
export function removeSpaces(str) {
  return str.replace(/\s+/g, "");
}

import { useTranslation } from "react-i18next";
import "./NamesOfAllah.scss";
import namesOfAllahImage from "../../assets/images/names-of-allah.svg";
import namesOfAllahData from "../../assets/data/names-of-allah.json";
import soundLogo from "../../assets/logos/sound.svg";
import Footer from "../../components/layout/Footer";
import useSound from "use-sound";
import { useEffect } from "react";
import AudioPlayer from "../../components/common/audioPlayer/AudioPlayer";
import { GET_NAMES_OF_ALLAH_AUDIO } from "../../constants/apis.constants.ts";

const NamesOfAllah = () => {
  const { t } = useTranslation();

  useEffect(() => {});
  return (
    <div className="pages-position">
      <div className="allah-names-header">
        <img src={namesOfAllahImage} alt="" />
        <div className="audio-allah-names">
          <AudioPlayer
            audioUrl={GET_NAMES_OF_ALLAH_AUDIO}
            withBorder={true}
            colorInput={"rgb(217 215 206)"}
          ></AudioPlayer>
        </div>
      </div>
      <div className="allah-names-body">
        <div className="allah-names-container">
          {namesOfAllahData.map((name) => (
            <CardNameAllah name={name} key={name.id} />
          ))}
        </div>
        <Footer />
      </div>
    </div>
  );
};

const CardNameAllah = ({ name }) => {
  const [play] = useSound(name.url);
  return (
    <div className="card-name-allah" onClick={play}>
      <div className="arabic-name">
        <span>{name.arabic}</span>
      </div>
      <div className="name-pronunciation">
        <span>{name.pronunciation}</span>
      </div>
      <div className="name-meaning">
        <span>{name.french}</span>
      </div>
      <img src={soundLogo} alt="" />
    </div>
  );
};

export default NamesOfAllah;

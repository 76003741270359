import axios from "axios";
import {
  GET_LIST_SURAH_API,
  GET_QURAN_TEXT_AUDIO_API,
} from "../constants/apis.constants.ts";
import moment from "moment";
import { formatTime } from "../utils/helpers.js";

export const getQuranBySorah = async (numSorah, reciter) => {
  const response = await axios.get(
    `${GET_QURAN_TEXT_AUDIO_API}${numSorah}/${reciter}`
  );

  if (response) {
    return response.data;
  }
  return null;
};

export const getLisSurah = async () => {
  const response = await axios.get(`${GET_LIST_SURAH_API}`, {});

  if (response) {
    return response?.data?.data;
  }
  return [];
};

export const handlePositionBarTime = (
  audioUrl,
  newPosition,
  audioDuration,
  setCurrentTime,
  setCurrentPositionBarTime
) => {
  setCurrentPositionBarTime(() => newPosition);
  const format = audioDuration.length > 5 ? "H:mm:ss" : "m:ss";
  const durationSeconds =
    moment(audioDuration, format).seconds() +
    moment(audioDuration, format).minutes() * 60 +
    moment(audioDuration, format).hours() * 3600;
  const time = (durationSeconds * newPosition) / 100;
  setCurrentTime(formatTime(time, format));
  const audioElement: any = document.getElementById(audioUrl);
  if (audioElement) {
    audioElement.currentTime = time;
  }
};

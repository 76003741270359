import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./StoryPage.scss";
import { useNavigate, useParams } from "react-router-dom";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const StoryPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { nameProphet } = useParams();

  return (
    <div className="pages-position">
      <div className="pages-header">
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="icon"
          onClick={() => {
            navigate(-1);
          }}
        />
        <span className="title-page">
          {t(`prophet_story.TITLE`) + nameProphet}
        </span>
      </div>
      <div className="story-body"></div>
    </div>
  );
};

export default StoryPage;

import logoMarketPlace from "../assets/logos/logo-market-place.svg";
import logoMarketPlaceWhite from "../assets/logos/logo-market-place-white.svg";
import logoRelations from "../assets/logos/logo-relations.svg";
import logoRelationsWhite from "../assets/logos/logo-relations-white.svg";

export const SERVICES_LIST = [
  {
    title: "COMMUNITY_SERVICE",
    image: logoRelations,
    whiteImage: logoRelationsWhite,
    description: "RELATION_DESCRIPTION",
    more: "RELATION_MORE",
    link: "",
  },
  {
    title: "MARKETPLACE",
    image: logoMarketPlace,
    whiteImage: logoMarketPlaceWhite,
    description: "MARKETPLACE_DESCRIPTION",
    more: "MARKETPLACE_MORE",
    link: "",
  },
  // {
  //   title: "CHAT_BOT",
  //   image: logoChatBot,
  //   whiteImage: logoChatBotWhite,
  //   description: "CHAT_BOT_DESCRIPTION",
  //   more: "CHAT_BOT_MORE",
  //   link: "",
  // },
];

import { useTranslation } from "react-i18next";
import "./MosquePage.scss";
import MyGoogleMap from "../../components/specific/googleMap/MyGoogleMap.tsx";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks.ts";
import { fetchListMosques } from "../../features/mosque/mosqueThunk.ts";
import positionIconWhite from "../../assets/icons/position-white.svg";
import positionIcon from "../../assets/icons/position.svg";
import { removeDiacritics, removeSpaces } from "../../utils/helpers.js";
import Loader from "../../components/common/Loader/Loader.jsx";
import Footer from "../../components/layout/Footer.jsx";
import mosqueVideo from "../../assets/videos/mosque-2.mp4";
import { selectMosque } from "../../features/mosque/mosqueSlice.ts";

const MosquePage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const listMosques = useAppSelector((state) => state.mosque.listMosques);
  const loading = useAppSelector((state) => state.mosque.loading);
  const [listMosquesFiltered, setListMosquesFiltered] = useState([]);
  const [position, setPosition] = useState({ lat: 0, lng: 0 });
  const selectedMosque = useAppSelector((state) => state.mosque.selectedMosque);
  const radius = useAppSelector((state) => state.mosque.radiusGettingMosque);

  useEffect(() => {
    getSetMyPosition();
  }, [radius]);

  const getSetMyPosition = () => {
    navigator.geolocation.getCurrentPosition(async (position) => {
      if (position) {
        setPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        dispatch(fetchListMosques({ position: position.coords, radius }));
      }
    });
  };

  useEffect(() => {
    setListMosquesFiltered(listMosques);
  }, [listMosques]);

  const handleSearch = (event) => {
    if (event.target.value) {
      const serchedString = removeSpaces(
        removeDiacritics(event.target.value.toLowerCase())
      );
      const filteredMosques = listMosques.filter((el) =>
        removeSpaces(removeDiacritics(el.name.toLowerCase())).includes(
          serchedString
        )
      );
      setListMosquesFiltered(filteredMosques);
    } else {
      setListMosquesFiltered(listMosques);
    }
  };

  return loading === "loading" ? (
    <Loader />
  ) : (
    <div className="pages-position">
      <div className="header-mosque">
        <div className="">
          <video autoPlay muted loop id="video-mosque">
            <source src={mosqueVideo} type="video/mp4" />
            Your browser does not support HTML video.
          </video>
        </div>
      </div>
      <div className="mosque-body">
        <div className="list-mosques-container">
          <div className="mosques">
            <div>
              <input
                type="search"
                placeholder={t("mosque.FIND_MOSQUE")}
                onChange={handleSearch}
              />
            </div>
            <div className="list-mosques">
              {listMosquesFiltered.map((mosque, index) => (
                <CardMosque
                  mosque={mosque}
                  key={index}
                  selected={
                    selectedMosque && selectedMosque.name === mosque.name
                  }
                ></CardMosque>
              ))}
            </div>
          </div>
        </div>
        <div className="mosque-map">
          <MyGoogleMap
            center={position}
            listPositions={listMosquesFiltered?.map(
              (mosque) => mosque.location
            )}
            itenerary={
              selectedMosque
                ? { from: position, to: selectedMosque.location }
                : null
            }
            myPosition={position}
          ></MyGoogleMap>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const CardMosque = ({ mosque, selected }) => {
  const dispatch = useAppDispatch();
  const getitInerary = (mosque) => {
    dispatch(selectMosque(mosque));
  };

  return (
    <div
      className={"card-mosque " + (selected ? "selected-mosque" : "")}
      onClick={() => {
        getitInerary(mosque);
      }}
    >
      <img src={mosque.photo} alt="photo mosque" className="image-mosque" />
      <div>
        <span className="mosque-name">{mosque.name}</span>

        <div className="mosque-distance">
          <img
            src={selected ? positionIconWhite : positionIcon}
            alt="position"
            className="position-icon"
          />
          <span>{mosque.distance?.text}</span>
          <span>{mosque.duration?.text}</span>
        </div>
      </div>
    </div>
  );
};
export default MosquePage;

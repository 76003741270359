import React, { useState, useEffect, useRef } from "react";
import "./chatbot.scss";
import chatbotIcon from "../../../assets/icons/chatbot-icon.svg";
import spalshrobot from "../../../assets/images/chatbot/Group 1261153030.png";
import Lottie from "lottie-react";
import loading from "../../../assets/lottie/loadingChat.json";
import { GoogleGenerativeAI } from "@google/generative-ai";

const Chatbot = () => {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isSplashVisible, setIsSplashVisible] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [isSplashBurn, setIsSplashBurn] = useState(false);
  const [loadingResponse, setLoadingResponse] = useState(false);
  const end = useRef(null);
  const genAI = new GoogleGenerativeAI(
    "AIzaSyCh8kcXIm6fhtxICMoJjlmv5vHQtcYtIrw"
  ); // cle avec mon email  /lien generation du cle /https://ai.google.dev/gemini-api/docs/api-key?hl=fr

  const handleButtonClick = () => {
    if (window.innerHeight > 300) {
      setIsChatOpen(true);
      setIsButtonVisible(false);
      setIsSplashVisible(true);
    }
  };

  const handleCloseChat = () => {
    setIsChatOpen(false);
    setIsButtonVisible(true);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSendMessage = async () => {
    if (inputValue.trim()) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: inputValue, fromUser: true },
      ]);
      setInputValue("");
      await handleBotResponse(inputValue);
    }
  };

  const handleBotResponse = async (userInput) => {
    setLoadingResponse(true);
    const prompt = `User: ${userInput}\nAssistant:`;
    const model = genAI.getGenerativeModel({ model: "gemini-pro" });

    try {
      const result = await model.generateContent(prompt);
      const response = await result.response.text();
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: response, fromUser: false },
      ]);
    } catch (error) {
      console.error("Error fetching response from AI:", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          text: "I'm sorry, I couldn't retrieve a response at this time.",
          fromUser: false,
        },
      ]);
    } finally {
      setLoadingResponse(false);
    }
  };

  useEffect(() => {
    let splashTimer;
    if (isSplashVisible && !isSplashBurn) {
      splashTimer = setTimeout(() => {
        setIsSplashVisible(false);
        handleBotResponse("hello");
        setIsSplashBurn(true);
      }, 3000);
    }
    return () => clearTimeout(splashTimer);
  }, [isSplashBurn, isSplashVisible]);

  useEffect(() => {
    if (messages.length > 0) {
      const scrollTimeout = setTimeout(() => {
        end.current?.scrollIntoView({ behavior: "smooth" });
      }, 300);

      return () => clearTimeout(scrollTimeout);
    }
  }, [messages]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerHeight < 300) {
        setIsChatOpen(false);
        setIsButtonVisible(true);
      } else {
        setIsButtonVisible(true);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  return (
    <div>
      {isButtonVisible && (
        <button
          className={"chatbot-button"}
          onClick={handleButtonClick}
        ></button>
      )}

      {isChatOpen && (
        <div className="chatbot-container">
          <div className="chatbot-header">
            <img src={chatbotIcon} alt="Chatbot Icon" />
            <span> Virtual Assistant</span>
            <button className="close-button" onClick={handleCloseChat}>
              <i className="material-icons" style={{ fontWeight: 600 }}>
                remove
              </i>
            </button>
          </div>

          <div className="chatbot-messages">
            {isSplashVisible && !isSplashBurn && (
              <div className="splash-screen">
                <img src={spalshrobot} alt="Splash" />
                <Lottie
                  className="Lotti"
                  animationData={loading}
                  loop={true}
                  autoplay={true}
                />
              </div>
            )}
            {messages.map((msg, index) => (
              <div
                key={index}
                className={msg.fromUser ? "message user" : "message bot"}
              >
                {!msg.fromUser && (
                  <div className="botAvatar">
                    <img src={chatbotIcon} alt="Bot Avatar" />
                  </div>
                )}
                <div className="msgtTxt">{msg.text}</div>
              </div>
            ))}
            {loadingResponse && <div className="loading">Loading...</div>}
            <div ref={end} />
          </div>

          <div className="chatbot-input">
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              placeholder="Message..."
            />
            <button onClick={handleSendMessage}>
              <i className="material-icons" style={{ color: "#406259" }}>
                send
              </i>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Chatbot;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { getLisSurah, getQuranBySorah } from "../../services/quran.service.ts";

export const fetchQuranBySorah = createAsyncThunk(
  "fetchQuranBySorah",
  async (dataQuoran: { numSorah: number; reciter: string }) => {
    const data = await getQuranBySorah(dataQuoran.numSorah, dataQuoran.reciter);
    return data;
  }
);

export const fetchListSurah = createAsyncThunk("fetchListSurah", async () => {
  const response = await getLisSurah();

  return response;
});

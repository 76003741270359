import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "./ConditionsGenerales.scss";
import Footer from "../../components/layout/Footer";

const ConditionsGenerales = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="pages-position">
      <div className="pages-header">
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="icon"
          onClick={() => {
            navigate(-1);
          }}
        />
        <span className="title-page">{t("conditions_generales.TITLE")}</span>
      </div>
      <div className="conditions-generales-body">
        <div>
          <h2>1. Introduction</h2>
          <p>
            Bienvenue sur muslimcommunity.com, une plateforme dédiée à la
            communauté musulmane et non musulmane pour fournir des informations
            et des services liés à l'Islam, aux relations halal, et à la vente
            de produits associés. En accédant à ce site, vous acceptez de
            respecter et d'être lié par les présentes Conditions Générales
            d'Utilisation (CGU). Si vous n'acceptez pas ces conditions, veuillez
            ne pas utiliser notre site.
          </p>
        </div>
        <div>
          <h2>2. Description des Services Muslim</h2>
          <p>
            Community propose une variété de services, y compris mais sans s'y
            limiter :
          </p>
          <div>
            <h3>2.1 Heures de Prière </h3>
            <ul>
              <li>
                <span className="font-bold">
                  Affichage des horaires de prière :
                </span>
                <span>
                  Informations précises sur les horaires de prière quotidiens
                  (Fajr, Dhuhr, Asr, Maghrib, Isha) basées sur votre
                  localisation.
                </span>
              </li>
              <li>
                <span className="font-bold">Alertes de prière :</span>
                <span>
                  Notifications et rappels pour les heures de prière via
                  l'application mobile.
                </span>
              </li>
              <li>
                <span className="font-bold">Calendrier des prières :</span>
                <span>
                  Calendrier mensuel et annuel des horaires de prière.
                </span>
              </li>
            </ul>
          </div>
          <div>
            <h3>2.2 Cours Islamiques</h3>
            <p>
              Vidéos et textes couvrant divers aspects de la foi islamique, y
              compris la jurisprudence (Fiqh), la théologie (Aqidah), et
              l'histoire islamique.
            </p>
          </div>
          <div>
            <h3>2.3 Piliers de l'Islam</h3>
            <ul>
              <li>
                <span className="font-bold">Informations détaillées :</span>
                <span>
                  Articles et vidéos expliquant les cinq piliers de l'Islam
                  (Shahada, Salat, Zakat, Sawm, Hajj).
                </span>
              </li>
              <li>
                <span className="font-bold">Guides pratiques :</span>
                <span>
                  Instructions étape par étape pour accomplir les piliers, y
                  compris les exigences et les pratiques recommandées.
                </span>
              </li>
            </ul>
          </div>
          <div>
            <h3>2.4 Récitation et Écoute du Saint Coran</h3>
            <ul>
              <li>
                <span className="font-bold">Lecteurs multiples :</span>
                <span>Choix parmi divers récitateurs renommés du Coran.</span>
              </li>
              <li>
                <span className="font-bold">Traductions :</span>
                <span>
                  Disponibilité des traductions dans plusieurs langues.
                </span>
              </li>
              <li>
                <span className="font-bold">Recherche par sourah:</span>
                <span>
                  Fonctionnalité pour trouver rapidement des souar spécifiques.
                </span>
              </li>
            </ul>
          </div>
          <div>
            <h3>2.5 Localisation des Mosquées</h3>
            <ul>
              <li>
                <span className="font-bold">Carte interactive :</span>
                <span>
                  Localisation des mosquées à proximité avec des informations
                  détaillées.
                </span>
              </li>
              <li>
                <span className="font-bold">Itinéraires :</span>
                <span>
                  Directions et suggestions de trajet pour atteindre les
                  mosquées.
                </span>
              </li>
            </ul>
          </div>
          <div>
            <h3>2.6 Dhikr & Dua</h3>
            <ul>
              <li>
                <span className="font-bold">Collection de Dhikr :</span>
                <span>
                  Liste de Dhikr (évocations) recommandés pour diverses
                  occasions et moments de la journée.
                </span>
              </li>
              <li>
                <span className="font-bold">Dua (supplications) :</span>
                <span>
                  Recueil de supplications pour différentes situations, avec
                  traductions et explications.
                </span>
              </li>
              <li>
                <span className="font-bold"> Audio et texte :</span>
                <span>Options pour écouter les Dhikr et Dua ou les lire.</span>
              </li>
            </ul>
          </div>
          <div>
            <h3>2.7 Instructions sur le Wudhu, Salat, Hajj et Omra</h3>
            <ul>
              <li>
                <span className="font-bold"> Wudhu (ablutions) :</span>
                <span>
                  Guide détaillé pour accomplir correctement les ablutions
                  rituelles, avec des illustrations et des vidéos.
                </span>
              </li>
              <li>
                <span className="font-bold">Salat (prière) :</span>
                <span>
                  Instructions complètes pour effectuer la prière, y compris les
                  postures, les récitations et les intentions.
                </span>
              </li>
              <li>
                <span className="font-bold"> Hajj (pèlerinage) :</span>
                <span>
                  Informations complètes sur le pèlerinage à La Mecque, y
                  compris les rituels obligatoires et recommandés, les
                  préparatifs et les conseils pratiques.
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div>
          <h2>3. Utilisation du Site</h2>
          <ul>
            <li>
              <span className="font-bold">Inscription :</span>
              <span>
                Certains services peuvent nécessiter une inscription. Vous vous
                engagez à fournir des informations exactes et à les mettre à
                jour régulièrement.
              </span>
            </li>
            <li>
              <span className="font-bold">Compte Utilisateur :</span>
              <span>
                Vous êtes responsable de la sécurité de votre compte et de la
                confidentialité de votre mot de passe. Toute activité effectuée
                via votre compte est de votre responsabilité.
              </span>
            </li>
            <li>
              <span className="font-bold">Utilisation Acceptable :</span>
              <span>
                Vous vous engagez à utiliser le site de manière légale et
                respectueuse. Vous ne devez pas utiliser le site pour des
                activités illégales ou nuisibles.
              </span>
            </li>
          </ul>
        </div>

        <div>
          <h2>4. Contenu Utilisateur</h2>
          <ul>
            <li>
              <span className="font-bold">Soumission de Contenu :</span>
              <span>
                En soumettant du contenu (texte, images, vidéos, etc.) sur
                Muslim Community, vous accordez à la plateforme une licence
                mondiale, non exclusive et gratuite d'utiliser, reproduire,
                modifier, et distribuer ce contenu.
              </span>
            </li>
            <li>
              <span className="font-bold">Propriété Intellectuelle :</span>
              <span>
                Vous devez détenir les droits nécessaires pour soumettre du
                contenu. Muslim Community ne sera pas responsable de toute
                violation des droits d'auteur ou de propriété intellectuelle par
                les utilisateurs.
              </span>
            </li>
          </ul>
        </div>

        <div>
          <h2>5. Protection des Données Personnelles</h2>
          <p>
            Nous prenons la confidentialité de vos données au sérieux. Veuillez
            consulter notre Politique de Confidentialité pour plus de détails
            sur la collecte, l'utilisation et la protection de vos informations
            personnelles.
          </p>
        </div>

        <div>
          <h2>6. Limitation de Responsabilité</h2>
          <p>
            Muslim Community s'efforce de fournir des informations exactes et à
            jour. Toutefois, nous ne garantissons pas l'exactitude,
            l'exhaustivité ou la pertinence des informations fournies. Nous ne
            serons pas responsables des pertes ou dommages résultant de
            l'utilisation du site.
          </p>
        </div>

        <div>
          <h2>7. Modifications des CGU</h2>
          <p>
            Nous nous réservons le droit de modifier ces CGU à tout moment. Les
            modifications seront publiées sur cette page et la date de mise à
            jour sera indiquée. Il est de votre responsabilité de vérifier
            régulièrement les CGU pour rester informé des changements.
          </p>
        </div>

        <div>
          <h2>8. Loi Applicable et Juridiction</h2>
          <p>
            Ces CGU sont régies par la loi française. Tout litige relatif à
            l'utilisation du site sera soumis à la compétence exclusive des
            tribunaux français.
          </p>
        </div>

        <div>
          <h2>9. Contact</h2>
          <p>
            Pour toute question concernant ces CGU, vous pouvez nous contacter à
            l'adresse suivante : contact@mslmcommunity.com.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ConditionsGenerales;

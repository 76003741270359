import { useNavigate } from "react-router-dom";
import "./Button.scss";

const Button = ({ children, from }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (from === "home") {
      navigate("about");
    }
  };
  return (
    <button className="green-button" onClick={handleClick}>
      {children}
    </button>
  );
};
export default Button;

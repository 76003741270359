import { useTranslation } from "react-i18next";
import "./ListSheikhPage.scss";
import { LIST_SHEIKH } from "../../constants/static_data.constants.ts";
import Footer from "../../components/layout/Footer.jsx";

const ListSheikhPage = () => {
  const { t } = useTranslation();

  return (
    <div className="pages-position">
      <div className="pages-header">
        <span className="title-page">{t("list_sheikhs.TITLE")}</span>
      </div>
      <div className="sheikh-body">
        {LIST_SHEIKH.map((sheikh) => (
          <CardSheikh sheikh={sheikh} />
        ))}
      </div>
      <Footer />
    </div>
  );
};

const CardSheikh = ({ sheikh }) => {
  return (
    <div className="card-sheikh">
      <h2>{sheikh.name}</h2>
      <span>{sheikh.description}</span>
    </div>
  );
};

export default ListSheikhPage;
